import { HttpStatusCode, Problems } from "@zuplo/errors";
import logger from "loglevel";
/**
 * This is the list of errors that are "expected" (i.e.) they are
 * errors that show when known conditions happen like the user
 * doesn't have permission to perform the action.
 *
 * Errors listed here show more friendly error messages and don't
 * show stack traces or other technical details
 */
export const EXPECTED_ERROR_TYPES = [
  Problems.USER_HAS_NO_ACCESS_TO_ACCOUNT.type,
  Problems.USER_HAS_NO_ACCESS_TO_PROJECT.type,
  Problems.MAX_ALLOWED_PROJECTS_LIMIT_REACHED.type,

  // These are portal specific errors
  "https://zup.fail/account-not-found",
  "https://zup.fail/project-not-found",
  "https://zup.fail/environment-not-found",
];

/**
 * For throwing custom error in the portal that have
 * the same error shape as API errors for consistency
 * when displaying error messages
 */
export class ZuploError extends Error {
  public type: string;
  public status?: number;
  public detail?: string;
  public instance?: string;
  public traceId?: string;

  constructor(
    {
      title,
      type,
      status,
      detail,
      instance,
      traceId,
    }: {
      title: string;
      type: string;
      status?: HttpStatusCode;
      detail?: string;
      instance?: string;
      traceId?: string;
    },
    options?: ErrorOptions,
  ) {
    super(title, options);
    this.name = "ZuploError";
    this.type = type;
    this.status = status;
    this.detail = detail;
    this.instance = instance;
    this.traceId = traceId;
  }
}

/**
 * For Zuplo API error responses containing the response data:
 * {
 *   "type": "https://zup.fail/foo",
 *   "title": "Error message",
 *   "status": 400,
 *   "detail": "Something happened",
 *   "instance": "/accounts/123"
 * }
 */
export class ZuploApiError extends Error {
  /**
   * @deprecated
   */
  public code?: number;
  public type?: string;
  public status?: HttpStatusCode;
  public detail?: string;
  public instance?: string;
  public traceId?: string;
  public title?: string;

  constructor(message: string, options?: ErrorOptions & { traceId?: string }) {
    super(message, options);
    this.traceId = options?.traceId;
    this.name = "ZuploApiError";
  }

  async tryReadResponse(response: Response) {
    try {
      this.status = response.status;
      const text = await response.clone().text();
      if (text) {
        const data = JSON.parse(text);
        // Attempt to get the error code
        if ("code" in data && typeof data.code === "number") {
          this.code = data.code;
        } else if ("code" in data && typeof data.code === "string") {
          this.code = parseInt(data.code);
        }

        if ("type" in data && typeof data.type === "string") {
          this.type = data.type;
        }
        if ("detail" in data && typeof data.detail === "string") {
          this.detail = data.detail;
        }
        if ("instance" in data && typeof data.instance === "string") {
          this.instance = data.instance;
        }
        if ("title" in data && typeof data.title === "string") {
          this.title = data.title;
        }

        // If we have a real error message, replace the fallback
        if ("title" in data && typeof data.title === "string") {
          this.message = data.title;
        } else if ("message" in data && typeof data.message === "string") {
          this.message = data.message;
        }
      }
    } catch (err) {
      logger.debug(err);
    }
  }

  setTraceId(traceId: string) {
    this.traceId = traceId;
  }
}
